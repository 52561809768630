import { UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { } from "react";
import { useTracked } from '../../state';

// tslint:disable-next-line: variable-name
export const LoginForm = (props) => {

    const [state, dispatch] = useTracked();

    const onFinish = async (e) => {
        dispatch({ type: 'LOGIN_START', request: { loginPostRequest: { email: e.username } } });
    };

    const onFinishFailed = (errorInfo) => {
        // Form is not valid
        // console.log('Failed:', errorInfo);
    };

    return (
        <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} hideRequiredMark={true}>
            <Form.Item label="" name="username" style={{ marginTop: 20 }} rules={[{ required: true, message: 'Du må skrive inn brukernavn!' }]}>
                <Input disabled={state.login.isLoading} prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Brukernavn" />
            </Form.Item>
            <Form.Item>
                <Button type="default" htmlType="submit" loading={state.login.isLoading} icon={<UnlockOutlined />}>
                    Logg på
              </Button>
            </Form.Item>
        </Form>
    );
};
