import { LockOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from 'antd';
import React, { useEffect } from "react";
import { useTrackedState, useDispatch } from "../../state";
import { navigate } from "@reach/router";

// tslint:disable-next-line: variable-name
export const VerifyForm = (props) => {

    const state = useTrackedState();
    const dispatch = useDispatch();

    const onFinish = async (e) => {
        dispatch({ type: 'LOGIN_VERIFY', request: { loginVerifyRequest: { authId: state.persisted.authId, authCode: e.verifyCode, rememberMe: e.rememberMe } } });
    };

    useEffect(() => {
        if (state.login.isAuthorized) {
            navigate("/dashboard");
        }
    }, [state]);

    const onFinishFailed = (errorInfo) => {
        // Form is not valid
        // console.log('Failed:', errorInfo);
    };

    return (
        <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} hideRequiredMark={true}>
            <Form.Item name="verifyCode" style={{ marginTop: 20, marginBottom: 12 }} rules={[{ required: true, message: 'Du må fylle inn koden' }]}>
                <Input autoComplete="off" disabled={state.login.isLoading} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Koden" />
            </Form.Item>
            <Form.Item name="rememberMe" rules={[{ required: false, type: "boolean" }]} valuePropName="checked">
                <Checkbox >Husk meg</Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type="default" htmlType="submit" loading={state.login.isLoading}>
                    Logg på
              </Button>
            </Form.Item>
        </Form>
    );
};
