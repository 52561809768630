import React, { useEffect } from 'react';
import Login from '../components/Login';

import { Helmet } from "react-helmet";
import { navigate } from '@reach/router';
import { useTracked, ActionTypes } from '../state';
import { validateToken } from '../state/actions';

function Index({ children, location }) {

    const [state, dispatch] = useTracked();

    useEffect(() => {
        dispatch({ type: ActionTypes.SET_LOCATION, location: location.pathname });
    }, [location]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>kinetik | Login</title>
                <link rel="canonical" href="https://kinetik.on.convivial.no" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap" rel="stylesheet" />
            </Helmet>
            <Login location={location} />
        </>
    );
}

export default Index;
