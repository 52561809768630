import React, { useEffect } from "react";
import { Form, Card, Alert } from 'antd';
import queryString from 'query-string';

// @ts-ignore
// import Logo from "../../../static/logo.inline.svg";

// @ts-ignore
import styles from "./login.module.css";

import { LoginForm } from "./ILoginForm";
import { VerifyForm } from "./IVerifyForm";
import { useTrackedState, useDispatch } from "../../state";

function Logo() {
    return (
        <div style={{ marginLeft: 0, marginRight: "auto", color: "#4BB2E7", fontSize: 24, fontWeight: 700, borderColor: "#E2E2E2", borderRadius: 6, borderWidth: 2, borderStyle: "solid", width: 85, height: 34, paddingTop: 3, lineHeight: 1, textAlign: "center" }}>kinetik</div>
    );
}

function LogoSmall() {
    return (
        <div style={{ display: "inline-block", margin: "0 auto", color: "#4BB2E7", fontSize: 16, fontWeight: 700, borderColor: "#E2E2E2", borderRadius: 6, borderWidth: 2, borderStyle: "solid", width: 65, height: 28, paddingTop: 5, lineHeight: 1, textAlign: "center" }}>kinetik</div>
    );
}

function Login({ location }) {

    const state = useTrackedState();
    const dispatch = useDispatch();

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const code = parsed.code as string;
        if (code !== undefined && code !== null && state.persisted.authId !== null) {
            dispatch({ type: 'LOGIN_VERIFY', request: { loginVerifyRequest: { authId: state.persisted.authId, authCode: code, rememberMe: true } } });
        }
    }, [location, state.persisted]);
    return (
        <div className={styles.container}>
            <Card title="Innlogging" extra={<div style={{marginBottom: 12}}><LogoSmall /></div>} style={{ borderRadius: "6px" }} className={styles.shadow}>
                {state.login.isUnauthorized ? <Alert message="Ukjent brukernavn" type="warning" showIcon closable={true} /> : <></>}
                {state.login.isInvalidAuthCode ? <Alert message="Ukjent autorisasjonskode" type="warning" showIcon closable={true} /> : <></>}
                {state.login.isWaitingForVerify ? <Alert message="Tast inn koden under, eller trykk på linken i mailen" type="info" showIcon closable={true} /> : <></>}
                {state.login.isVerifyingCode ? <Alert message="Verifiserer koden" type="info" showIcon closable={true} /> : <></>}
                {state.login.isWaitingForVerify ? <VerifyForm /> : <LoginForm />}
            </Card>
        </div>
    );
}

export default Login;
